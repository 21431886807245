
<template>
    <div>
      <div class="" v-if="loading">
        <app-spinner></app-spinner>
      </div>
      <v-row v-else>
        
       
        <template v-if="returnAbility('absent:report')">
        <v-col md="4" >
          <div style="cursor: pointer; "  @click="
                  $router.push(`absentReport`)
                ">
            <v-card elevation="3" class="mt-5 mx-5" width="320" style="background-color: #2fb863 !important; color: white;" >
            <v-card-title class="justify-center">
              
              <h2>{{ $t('Absent Report') }}</h2>
             
            </v-card-title>
            <v-card-actions class="justify-space-between"  >
              
            </v-card-actions>
          </v-card>
          </div>
        </v-col>
      </template>
      <template v-if="returnAbility('mark:report')" >
        <v-col md="4" >
          <div style="cursor: pointer; "  @click="
                  $router.push(`resultsReport`)
                ">
            <v-card elevation="3" class="mt-5 mx-5" width="320" style="background-color: #2fb863 !important; color: white;" >
            <v-card-title class="justify-center">
              
              <h2>{{ $t('Results Report') }}</h2>
             
            </v-card-title>
            <v-card-actions class="justify-space-between"  >
              
            </v-card-actions>
          </v-card>
          </div>
        </v-col>
      </template>
     
       
      </v-row>
    </div>
  </template>

 <script>
 import axios from "axios";

 export default {
   components: {
    
   },
   data() {
     return {
       items: [],
       loading: false,
       templates: [],
     };
   },
   methods: {
    
    
   },
   created() {
    

   },
 };
 </script>
 
  <style>
  .accounting-templates__spinner-wrapper {
    width: 100%;
    height: 89vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  </style>